/**
 * Default values that are returned when there are no documents created on an Account
 */
export const DEFAULT_DOCUMENTS_RESPONSE = {
  invoices: [],
  estimates: [],
  guides: [],
  totals: {
    count: 0,
    pages: 0,
    statuses: [],
    total: 0,
    total_before_taxes: 0,
    total_canceled: 0,
    total_canceled_with_taxes: 0,
    total_corrections: 0,
    total_corrections_with_taxes: 0,
    total_drafts: 0,
    total_drafts_with_taxes: 0,
    total_due: 0,
    total_due_with_taxes: 0,
    total_overdue: 0,
    total_overdue_with_taxes: 0,
    total_paid_before_taxes: 0,
    total_paid_with_taxes: 0,
    total_taxes: 0,
  },
};

/**
 * Default values that are returned when there are no sequences created on an Account
 */
export const DEFAULT_SEQUENCES_RESPONSE = {
  sequences: [],
  totals: {
    pages: 0,
  },
};

export const FINAL_CONSUMER_VAT = '999999990';

export const DEFAULT_COUNTRY = 'Portugal';
export const DEFAULT_PHONE_CONTRY = 1; // Portugal

export const DEFAULT_TAX = 'IVA23';
export const DEFAULT_NO_TAX = 'Isento';
export const DEFAULT_TAX_REASON = 'M00';

export const DEFAULT_PLUGIN_API = 0;
