import React from 'react';
import { Route } from 'react-router-dom';
import CustomizeDocumentLayout from '../../../components/templates/customize-document/CustomizeDocumentLayout';
import DigitalSignatureLayout from '../../../components/templates/settings/digital-signature/DigitalSignatureLayout';
import ManageUsersLayout from '../../../components/templates/settings/manage-users/ManageUsersLayout';
import ManageIVALayout from '../../../components/templates/settings/manage-iva/ManageIVALayout';
import ManageAPILayout from '../../../components/templates/settings/manage-api/ManageAPILayout';
import { CUSTOMIZE_DOCUMENT, SETTINGS, USERS } from '../../../constants/url';

/**
 * Creates the routes necessary to render the account settings
 *
 * @returns {Array} routes created on the AccountSettings submenu
 */
export const AccountSettingsRoutes = (layoutProps) => [
  <Route exact path={[CUSTOMIZE_DOCUMENT]} key={CUSTOMIZE_DOCUMENT}>
    <CustomizeDocumentLayout documentsTab={'CustomizeDocument'} />
  </Route>,
  <Route path={[SETTINGS.DIGITAL_SIGNATURE]} key={SETTINGS.DIGITAL_SIGNATURE}>
    <DigitalSignatureLayout documentsTab={'DigitalSignature'} />
  </Route>,
  <Route exact path={[USERS.LIST]} key={USERS.LIST}>
    <ManageUsersLayout
      documentsTab={'ManageUsers'}
      windowLocation={layoutProps.windowLocation}
    />
  </Route>,
  <Route path={[SETTINGS.REGIME_IVA]} key={SETTINGS.REGIME_IVA}>
    <ManageIVALayout documentsTab={'RegimeIVA'} />
  </Route>,
  <Route path={[SETTINGS.MANAGE_API]} key={SETTINGS.MANAGE_API}>
    <ManageAPILayout documentsTab={'ManageAPI'} />
  </Route>,
];
