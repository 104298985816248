import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../atoms/icons/Icon';
import ButtonTooltip from '../../molecules/buttons/ButtonTooltip';
import { injectIntl } from 'react-intl';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const AlertWithButton = (props) => {
  const {
    alertType,
    buttonLabel,
    onClick,
    buttonDisabled,
    alertMessage,
    tooltipMessage,
    tooltipType,
  } = props;

  return (
    <div
      data-testid={props.id}
      className={`alert ${alertType} d-flex-row text-align-left --icon align-items-center justify-content-between`}
    >
      <div className='status-info bold'>
        <Icon id={`${props.id}-status`} className={`icon fas fa-check`} />
        <Icon
          id={`${props.id}-icon`}
          className={`icon fas fa-exclamation-triangle`}
        />
        {props.intl.messages[alertMessage]}
      </div>

      <ButtonTooltip
        id={`${props.id}-button`}
        className='button button-secondary --small tooltip-hover'
        onClick={onClick}
        label={buttonLabel}
        disabled={buttonDisabled}
        tooltipMessage={tooltipMessage}
        tooltipType={tooltipType}
      />
    </div>
  );
};

AlertWithButton.propTypes = {
  alertType: PropTypes.string,
  iconClassName: PropTypes.string,
};

export default injectIntl(AlertWithButton);
