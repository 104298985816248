import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Span from '../../atoms/spans/Span';
import CheckBoxLabel from '../../molecules/labels/CheckBoxLabel';
import Icon from '../../atoms/icons/Icon';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 * Table row that renders document data.
 */
const RowSimple = (props) => {
  const addUserEmail = (document) => {
    return (
      <div className='cell'>
        <Span
          className='d-block text-label --small'
          label={`${props.intl.messages['email']}:`}
        />
        <div
          id={`${props.id}_emailRow-${props.index}`}
          className='text-description'
        >
          {document.email}
        </div>
      </div>
    );
  };

  const addUserRole = (document) => {
    return (
      <div className='cell'>
        <Span
          className='d-block text-label --small'
          label={`${props.intl.messages['profile']}:`}
        />
        <div className='text-description'>
          {props.intl.messages[document.role]}
        </div>
      </div>
    );
  };

  /**
   * Renders actions.
   * @function
   */
  const addEditButton = (document) => {
    return (
      <div
        className={`button button-icon-self vertical-sep justify-content-center d-flex align-items-center ${
          props.userId === String(document.id) || document.owner
            ? 'disabled'
            : ''
        }`}
        onClick={
          props.userId === String(document.id) || document.owner
            ? () => {}
            : () => {
                props.openModal(document);
              }
        }
      >
        <Icon className='icon fa fa-pencil-alt' />
      </div>
    );
  };

  return (
    <>
      <div className={`card --simple --manage-user-cell`}>
        <div className='cell'>
          <CheckBoxLabel
            className='checkbox'
            checked={props.selected}
            onChange={() => {
              props.isAllLinesSelected
                ? props.setSelectedLines([])
                : props.setSelectedLines(props.document.id);
            }}
            disabled={
              props.userId === String(props.document.id) || props.document.owner
            }
          />
        </div>
        <div className='mobile-left'>
          {addUserEmail(props.document)}
          {addUserRole(props.document)}
        </div>

        <div className='mobile-right --align-center'>
          {addEditButton(props.document)}
        </div>
      </div>
    </>
  );
};

RowSimple.propTypes = {
  documents: PropTypes.object,
  index: PropTypes.number,
  intl: PropTypes.object,
};

export default injectIntl(RowSimple);
